<template>
  <v-dialog
    :value="dialogs.changeReferralPercent"
    width="500"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Реферальная комиссия
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="close"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-row>
          <v-col :cols="12">
            <v-text-field
              v-model="users.forDialog.user.refRate"
              label="Пожизненные отчисления"
              max="100"
              type="number"
              dense
              outlined
              hide-details
              :spellcheck="false"
              class="centralize-append-icon"
            />
          </v-col>
          <v-col :cols="12">
            <v-text-field
              v-model="users.forDialog.user.refRateTemporary"
              label="Временные отчисления"
              max="100"
              type="number"
              dense
              outlined
              hide-details
              :spellcheck="false"
              class="centralize-append-icon"
            />
          </v-col>
          <v-col :cols="12">
            <v-text-field
              v-model="users.forDialog.user.refRateDuration"
              label="Длительность временных отчислений, в днях"
              dense
              outlined
              hide-details
              :spellcheck="false"
              class="centralize-append-icon"
              type="number"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="close"
        >
          Закрыть
        </v-btn>

        <v-btn
          small
          text
          color="primary"
          :loading="loading"
          @click="changePercent"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'UsersDialogChangeReferralPercent',

  data() {
    return {
      username: '',
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      users: 'users/users',
      dialogs: 'users/dialogs',
      filters: 'users/filters',
    }),
    ...mapFields('users', ['topbarSearch']),
  },

  created() {
    this.username = this.users.forDialog.user.username;
  },
  mounted() {
    if (this.users.forDialog.user.refRateDuration <= 0) {
      this.users.forDialog.user.refRateDuration = null;
    }
  },

  beforeDestroy() {
    this.$store.dispatch('users/clearForDialog');
  },

  methods: {
    close() {
      this.$store.dispatch('users/closeDialog', 'changeReferralPercent');
    },

    async changePercent() {
      this.loading = true;

      try {
        const response = await this.api.patch('admin/users/change-ref-rate',
          {
            userId: this.users.forDialog.user.id,
            refRate: this.users.forDialog.user.refRate,
            refRateDuration: this.users.forDialog.user.refRateDuration,
            temporaryRefRate: this.users.forDialog.user.refRateTemporary,
          });

        if (response.status === 200) {
          await this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Готово',
          });
        }

        this.close();
      } catch (err) {
        console.error(err);
      } finally {
        await this.$store.dispatch('users/loadUsers', {
          query: this.topbarSearch.searchInput,
          segments: this.filters.segments,
          grade: this.filters.grade,
          sortBy: this.filters.sortBy,
          order: this.filters.order,
        });
        this.loading = false;
      }
    },
  },

};
</script>

<template>
  <div>
    <dialog-add-user v-if="dialogs.add" />
    <dialog-referral-commission v-if="dialogs.referralCommission" />
    <dialog-change-plan v-if="dialogs.changePlan" />
    <dialog-change-password v-if="dialogs.changePassword" />
    <dialog-change-telegram-name v-if="dialogs.changeTelegram" />
    <dialog-add-payment v-if="dialogs.addPayment" />
    <dialog-transactions v-if="dialogs.showTransactions" />
    <dialog-transactions v-if="dialogs.showTransactions" />
    <users-dialog-suspicious-entrance v-if="dialogs.suspiciousEntrance" />
    <users-dialog-two-factor-by-email-enable-or-disable v-if="dialogs.twoFactorByEmailEnableOrDisable" />
    <users-dialog-delete-user v-if="dialogs.openDeleteUser" />
    <dialog-pay-referal v-if="dialogs.payReferal" />
    <dialog-change-referral-percent v-if="dialogs.changeReferralPercent" />
    <dialog-dolphin-pay-transactions v-if="dialogs.showDolphinPay" />
    <dialog-change-referral-percent />
    <dialog-abuse v-if="dialogs.abuse" />
    <dialog-rename-user v-if="dialogs.renameUser" />
    <dialog-disable-two-factor v-if="dialogs.disableTwoFactor"/>
    <users-dialog-history v-if="dialogs.history" />
    <users-dialog-reset-limit v-if="dialogs.resetLimit" />
    <dialog-user-info v-if="dialogs.userInfo" />
    <topbar />
    <main-table />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import UsersDialogResetLimit from '@/components/users/UsersDialogResetLimit.vue';
import UsersDialogSuspiciousEntrance from '@/components/users/UsersDialogSuspiciousEntrance.vue';
import UsersDialogTwoFactorByEmailEnableOrDisable from '@/components/users/UsersDialogTwoFactorByEmailEnableOrDisable.vue';
import DialogUserInfo from '@/components/users/UserInfoDialog.vue';
import UsersDialogDeleteUser from '../components/users/UsersDialogDeleteUser.vue';
import MainTable from '../components/users/UsersMainTable.vue';
import Topbar from '../components/users/UsersTopbar.vue';
import DialogAddUser from '../components/users/UsersDialogAddUser.vue';
import DialogReferralCommission from '../components/users/UsersDialogReferralCommission.vue';
import DialogChangePlan from '../components/users/UsersDialogChangePlan.vue';
import DialogAddPayment from '../components/users/UsersDialogAddPayment.vue';
import DialogTransactions from '../components/users/UsersDialogTransactions.vue';
import DialogPayReferal from '../components/users/UsersDialogPayReferal.vue';
import DialogChangeReferralPercent from '../components/users/UsersDialogChangeReferralPercent.vue';
import DialogDolphinPayTransactions from '../components/users/DolphinPayDialogTransactions.vue';
import DialogChangePassword from '../components/users/UsersDialogChangePassword.vue';
import DialogChangeTelegramName from '../components/users/UsersDialogChangeTelegramName.vue';
import DialogAbuse from '../components/users/UsersDialogAbuse.vue';
import DialogRenameUser from '../components/users/UsersDialogRenameUser.vue';
import DialogDisableTwoFactor from '../components/users/UsersDialogDisableTwoFactor.vue';
import UsersDialogHistory from '../components/users/UsersDialogHistory.vue';

export default {
  name: 'Users',

  components: {
    UsersDialogResetLimit,
    DialogUserInfo,
    UsersDialogHistory,
    UsersDialogTwoFactorByEmailEnableOrDisable,
    UsersDialogSuspiciousEntrance,
    UsersDialogDeleteUser,
    MainTable,
    Topbar,
    DialogAddUser,
    DialogReferralCommission,
    DialogChangePlan,
    DialogAddPayment,
    DialogTransactions,
    DialogPayReferal,
    DialogChangeReferralPercent,
    DialogDolphinPayTransactions,
    DialogChangePassword,
    DialogChangeTelegramName,
    DialogAbuse,
    DialogRenameUser,
    DialogDisableTwoFactor,
  },

  computed: {
    ...mapGetters({
      dialogs: 'users/dialogs',
    }),
  },

  created() {
    this.$store.dispatch('users/loadUsers');
  },

  beforeDestroy() {
    this.$store.dispatch('users/clearSelected');
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"custom-data-table",attrs:{"items":_vm.users.all,"item-key":"id","headers":_vm.cols,"fixed-header":"","height":_vm.innerHeight - 16 - 46 - 59,"items-per-page":_vm.footerOptions.itemsPerPage,"hide-default-footer":"","loading":_vm.loading.mainTable,"show-expand":"","expanded":_vm.expanded,"server":true,"server-items-length":_vm.users.all.length},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":_vm.onOptionsUpdate},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var user = ref.item;
return [_c('cell-actions',{attrs:{"user":user,"user-id":user.id}})]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
return [_c('div',{staticClass:"icon-container"},[_c('v-icon',{attrs:{"size":20,"disabled":!item.users.length},on:{"click":function($event){return _vm.handleExpansion(item, isExpanded)}}},[_vm._v(" "+_vm._s(!isExpanded ? 'mdi mdi-plus' : 'mdi mdi-minus')+" ")])],1)]}},{key:"item.refStat",fn:function(ref){
var user = ref.item;
return [_c('div',[_vm._v(" Баланс: "),_c('br'),_vm._v(" USD "+_vm._s(user.ref_stat.balance)+" ")]),_c('div',[_vm._v(" Рефералы: "+_vm._s(user.ref_stat.users_count)+" ")])]}},{key:"item.registrationDate",fn:function(ref){
var date = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(date.registrationDate ).format('DD.MM.YYYY')))])]}},{key:"item.username",fn:function(ref){
var user = ref.item;
return [_c('p',{class:{
          'blue--text': user.username
            .toUpperCase()
            .includes(_vm.topbarSearch.searchInput.toUpperCase())
        },staticStyle:{"margin":"0"}},[_vm._v(" "+_vm._s(user.username)+" "),(user.team_deleted_at)?_c('span',{staticClass:"red--text"},[_vm._v("(удалена тима)")]):_vm._e()]),(user.team_deleted_at)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"float":"right"},attrs:{"icon":"","inline":""},on:{"click":function($event){return _vm.restoreTeam(user.teamId)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-backup-restore ")])],1)]}}],null,true)},[_c('span',[_vm._v("Восстановить Тиму")])]):_vm._e(),(user.deleted_at)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"float":"right"},attrs:{"icon":"","inline":""},on:{"click":function($event){return _vm.restoreTeamUser(user.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-backup-restore ")])],1)]}}],null,true)},[_c('span',[_vm._v("Восстановить юзера и его профили")])]):_vm._e()]}},{key:"item.license",fn:function(ref){
        var user = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getPlanChipColor(user.plan),"outlined":"","label":"","small":""}},[_vm._v(" "+_vm._s(user.plan.toUpperCase())+" "+_vm._s(user.isCustomTariff === 1 ? ' CUSTOM' : '')+" ")]),_c('span',{staticClass:"mx-3"},[_c('span',{staticClass:"mr-2",style:({color: _vm.getColor(user.usersLimit, user.usersCount)})},[_c('v-icon',{staticStyle:{"color":"inherit","position":"relative","top":"-2px"},attrs:{"size":16}},[_vm._v(" mdi-account ")]),_vm._v(" "+_vm._s(_vm.usersCount(user) + "/" + user.usersLimit)+" ")],1),_c('span',{style:({color: _vm.getColor(user.browserProfilesLimit, user.browserProfilesCount)})},[_c('v-icon',{staticStyle:{"color":"inherit","position":"relative","top":"-2px"},attrs:{"size":16}},[_vm._v(" mdi-google-chrome ")]),_vm._v(" "+_vm._s(_vm.browserProfilesCount(user) + "/" + user.browserProfilesLimit)+" ")],1)]),_c('span',{staticClass:"mr-2"},[_vm._v(" до "+_vm._s(_vm.moment(user.subscriptionExpiration).format('DD.MM.YYYY'))+" ")]),(user.plan === 'free' &&
         user.browserProfilesLimit === 10)?_c('span',{staticClass:"reset-limit-span"},[_c('v-icon',{staticClass:"reset-limit-icon",attrs:{"size":16}},[_vm._v(" mdi-timelapse ")]),_vm._v(" "+_vm._s(user.countForCurrentMonth + "/" + user.limitForCurrentMonth)+" ")],1):_vm._e()]}},{key:"footer",fn:function(){return [_c('v-data-footer',{attrs:{"options":_vm.footerOptions,"pagination":_vm.footerPagination,"items-per-page-options":[10, 30, 50]},on:{"update:options":_vm.updateFooterOptions}})]},proxy:true},{key:"expanded-item",fn:function(ref){
         var item = ref.item;
return [_c('td',{staticClass:"rootUnderTable"},_vm._l((item.users),function(user){return _c('div',{key:user.id,staticClass:"containerUnderTable emptyTd"})}),0),_c('td',{staticClass:"rootUnderTable"},_vm._l((item.users),function(user){return _c('p',{key:user.id,staticClass:"containerUnderTable",class:{
            'blue--text': user.username
              .toUpperCase()
              .includes(_vm.topbarSearch.searchInput.toUpperCase()),
          }},[_vm._v(" "+_vm._s(user.username)+" ")])}),0),_c('td',{staticClass:"rootUnderTable"},_vm._l((item.users),function(user){return _c('p',{key:user.id,staticClass:"containerUnderTable"},[_vm._v(" "+_vm._s(user.telegram)+" ")])}),0),_c('td',{staticClass:"rootUnderTable"},_vm._l((item.users),function(id){return _c('div',{key:id.id,staticClass:"containerUnderTable"})}),0),_c('td',{staticClass:"rootUnderTable"},_vm._l((item.users),function(user){return _c('p',{key:user.id,staticClass:"containerUnderTable"},[_vm._v(" "+_vm._s(user.role)+" ")])}),0),_c('td',{staticClass:"rootUnderTable"},_vm._l((item.users),function(user){return _c('div',{key:user.id,staticClass:"containerUnderTable"})}),0),_c('td',{staticClass:"rootUnderTable"},_vm._l((item.users),function(user){return _c('div',{key:user.id,staticClass:"buttonContainer containerUnderTable"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.showUserHistory(user)}}},[_vm._v(" История ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openSuspiciousEntranceDialog(user)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Показать подозрительный входы")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openTwoFactorByEmailEnableOrDisableDialog(user)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-account-sync ")])],1)]}}],null,true)},[(user.twoFactorByEmailEnable)?_c('span',[_vm._v(" Включить Гео 2FA ")]):_c('span',[_vm._v(" Выключить Гео 2FA ")])]),_c('v-btn',{attrs:{"icon":"","disabled":!user.twoFactorEnableDisable},on:{"click":function($event){return _vm.disableTwoFactor(user)}}},[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi mdi-two-factor-authentication ")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","inline":"","disabled":!user.deleted_at},on:{"click":function($event){return _vm.restoreTeamUser(user.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":15}},[_vm._v(" mdi mdi-account-reactivate-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Восстановить юзера и его профили")])]),(_vm.profile.manageUsers)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openRenameUser(user)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-rename-box ")])],1)]}}],null,true)},[_c('span',[_vm._v("Переименовать пользователя")])]):_vm._e(),(_vm.profile.manageUsers)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.getOneTimeLoginCode(user.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-map-marker-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v("Получить код если \"подозрительный вход\"")])]):_vm._e()],1)}),0)]}}],null,true),model:{value:(_vm.users.selected),callback:function ($$v) {_vm.$set(_vm.users, "selected", $$v)},expression:"users.selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
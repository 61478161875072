var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(_vm.user.id, 'User ID скопирован')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi mdi-identifier ")])],1)]}}])},[_c('span',[_vm._v("Копировать User ID")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.openChangePlan}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-newspaper-variant-outline ")])],1)]}}])},[_c('span',[_vm._v("Изменить тариф")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.addPayment}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-currency-usd ")])],1)]}}])},[_c('span',[_vm._v("Добавить платеж")])]),(_vm.profile.manageUsers)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.openChangePassword}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-key-outline ")])],1)]}}],null,false,4130186204)},[_c('span',[_vm._v("Изменить пароль")])]):_vm._e(),(_vm.profile.manageUsers)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.openChangeTelegram}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-message-processing ")])],1)]}}],null,false,1390240117)},[_c('span',[_vm._v("Изменить telegram пользователя")])]):_vm._e(),(_vm.profile.manageUsers)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.openRenameUser}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-rename-box ")])],1)]}}],null,false,235766333)},[_c('span',[_vm._v("Переименовать пользователя")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.referralCommission}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":18}},[_vm._v(" mdi-percent ")])],1)]}}])},[_c('span',[_vm._v("Реферальная комиссия")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.user.twoFactorEnableDisable},on:{"click":_vm.disableTwoFactor}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi mdi-two-factor-authentication ")])],1)]}}])},[_c('span',[_vm._v("Выключить двухфакторную аутентификацию")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.showTransactions}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-account-cash ")])],1)]}}])},[_c('span',[_vm._v("Показать транзакции")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.checkDisabled()},on:{"click":_vm.openDeleteUser}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-delete ")])],1)]}}])},[_c('span',[_vm._v("Удалить учётку")])]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.showUserHistory}},[_vm._v(" История ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.openUserDetailInformationPage}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-account-alert ")])],1)]}}])},[_c('span',[_vm._v("Детальная информация")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.openSuspiciousEntranceDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Показать подозрительный входы")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.openTwoFactorByEmailEnableOrDisableDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-account-sync ")])],1)]}}])},[(_vm.user.twoFactorByEmailEnable)?_c('span',[_vm._v(" Включить Гео 2FA ")]):_c('span',[_vm._v(" Выключить Гео 2FA ")])]),(_vm.profile.resetReferalBalance)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.payReferal}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-cash-check ")])],1)]}}],null,false,2605790426)},[_c('span',[_vm._v("Пометить транзакции оплаченными")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.restoreBPLoading},on:{"click":_vm.restoreBrowserProfiles}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-ghost ")])],1)]}}])},[_c('span',[_vm._v("Восстановить фантомные профили, если профилей меньше, чем показывает счетчик тарифа")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.openAbuse}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-lock-outline ")])],1)]}}])},[_c('span',[_vm._v("Абьюз")])]),(_vm.profile.manageUsers)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.getOneTimeLoginCode}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-map-marker-circle ")])],1)]}}],null,false,4076325486)},[_c('span',[_vm._v("Получить код если \"подозрительный вход\"")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.disableResetLimitButton},on:{"click":_vm.openResetLimitDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":20}},[_vm._v(" mdi-timelapse ")])],1)]}}])},[_c('span',[_vm._v("Сбросить лимит")])]),(!_vm.user.email_verified_at)?_c('v-btn',{on:{"click":_vm.forceVerifyEmail}},[_vm._v("Подтвердить почту (не подтверждена)")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
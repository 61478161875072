<template>
  <v-expansion-panels
    multiple
    class="user-details-right-side-panels"
    @change="handlePanelClick"
  >
    <user-info-details-panel
      title="Статусы"
      :items="dataState.statuses.list"
      dynamic-key="name"
      :get-next-page="() => getNextPage('statuses')"
    />
    <user-info-details-panel
      title="Папки"
      :items="dataState.folders.list"
      dynamic-key="name"
      :get-next-page="() => getNextPage('folders')"
    />
    <user-info-dialog-details-table-panel
      title="Пользователи"
      :items="dataState.users.list"
      :get-next-page="() => getNextPage('users')"
      :headers="usersHeaders"
    />
    <user-info-details-panel
      title="Вкладки"
      :items="dataState.tabs.list"
      :get-next-page="() => getNextPage('tabs')"
    />
    <user-info-details-panel
      title="Обратная связь"
      :items="dataState.feedbacks.list"
      dynamic-key="content"
      :get-next-page="() => getNextPage('feedbacks')"
    />
    <user-info-dialog-details-table-panel
      title="Транзакции"
      :items="dataState.transactions.list"
      :get-next-page="() => getNextPage('transactions')"
      :headers="transactionsHeaders"
    />
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex';
import UserInfoDialogDetailsPanel from '@/components/users/userInfo/UserInfoDialogDetailsPanel.vue';
import UserInfoDialogDetailsTablePanel from '@/components/users/userInfo/UserInfoDialogDetailsTablePanel.vue';

export default {
  name: 'UserInfoDetailsRightSide',
  components: { UserInfoDetailsPanel: UserInfoDialogDetailsPanel, UserInfoDialogDetailsTablePanel },
  data() {
    return {
      transactionsHeaders: [
        { text: 'Сумма', value: 'sum' },
        { text: 'Валюта', value: 'currency' },
        { text: 'Дата', value: 'created_at' },
      ],
      usersHeaders: [
        { text: 'username', value: 'username' },
        { text: 'displayName', value: 'displayName' },
        { text: 'role', value: 'role' },
        { text: 'telegram', value: 'telegram' },
      ],
      panels: ['statuses', 'folders', 'users', 'tabs', 'feedbacks', 'transactions'],
      dataState: {
        statuses: { page: 0, list: [], noMoreItems: false },
        folders: { page: 0, list: [], noMoreItems: false },
        users: { page: 0, list: [], noMoreItems: false },
        tabs: { page: 0, list: [], noMoreItems: false },
        feedbacks: { page: 0, list: [], noMoreItems: false },
        transactions: { page: 0, list: [], noMoreItems: false },
      },
    };
  },
  computed: {
    ...mapGetters({
      forDialog: 'users/forDialog',
    }),
  },
  methods: {
    async getNextPage(type) {
      const { page, noMoreItems, list } = this.dataState[type];

      if (noMoreItems) return;

      const response = await this.$store.dispatch('users/getUserInfo', { type, page: page + 1, id: this.forDialog.user.teamId });

      if (response?.data?.data) {
        this.dataState[type].list = [...list, ...response.data.data[type]];
        this.dataState[type].page += 1;

        if (!response.data.data[type].length) {
          this.dataState[type].noMoreItems = true;
        }
      }
    },

    handlePanelClick(activePanels) {
      if (!activePanels.length) return;

      const activeKeys = activePanels.map((index) => this.panels[index]);
      this.handleSplitForRequest(activeKeys);
    },

    handleSplitForRequest(panels) {
      panels.forEach((panel) => {
        const { page, noMoreItems } = this.dataState[panel];

        if (page === 0 && !noMoreItems) {
          this.getNextPage(panel);
        }
      });
    },
  },
};
</script>

<style>
.user-details-right-side-panels {
  height: fit-content;
  flex: 1;
  overflow: hidden;
}

.user-details-right-side-panels .v-expansion-panel:not(:first-child)::after {
  border: none;
}
</style>

export default {
  free: {
    usersHint: {
      default: 'На тарифе Free нельзя регулировать количество пользователей',
      custom: 'Минимум 2 пользователя',
    },
    profilesHint: {
      default: 'Количество профилей от 10 до 60',
      custom: 'Количество профилей от 10 до 60',
    },
    profilesValidation: {
      default: (value) => value >= 10 && value <= 60,
      custom: (value) => value >= 10 && value <= 60,
    },
    userValidation: {
      default: (value) => value === 1,
      custom: (value) => value <= 4 && value > 1,
    },
  },
  base: {
    usersHint: {
      default: 'Минимум 1 пользователь',
      custom: 'Минимум 1 пользователь',
    },
    profilesHint: {
      default: 'На тарифе Base нельзя регулировать количество профилей',
      custom: 'Укажите количество профилей от 101 до 299',
    },
    profilesValidation: {
      default: (value) => value === 100,
      custom: (value) => value > 100 && value <= 299,
    },
  },
  team: {
    usersHint: {
      default: 'Минимум 1 пользователь',
      custom: 'Минимум 1 пользователь',
    },
    profilesHint: {
      default: 'На тарифе Team нельзя регулировать количество профилей',
      custom: 'Укажите количество профилей от 301 до 999',
    },
    profilesValidation: {
      default: (value) => value === 300,
      custom: (value) => value > 300 && value <= 999,
    },
  },
  enterprise: {
    usersHint: {
      default: 'Минимум 1 пользователь',
      custom: 'Минимум 1 пользователь',
    },
    profilesHint: {
      default: 'Укажите количество профилей от 1000',
      custom: 'Укажите количество профилей от 1000',
    },
    profilesValidation: {
      default: (value) => value >= 1000,
      custom: (value) => value >= 1000,
    },
  },
};

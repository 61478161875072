<template>
  <v-card class="mt-10">
    <v-card-subtitle class="pt-4 pb-0 pl-6">
      Базовая инфа
    </v-card-subtitle>
    <div class="d-flex pa-6">
      <v-card
        max-width="400"
        color="#2C2C2C"
        elevation="0"
        class="d-flex flex-column"
      >
        <v-card-subtitle class="pt-2 pb-0 pl-2">
          Почта
        </v-card-subtitle>

        <v-card-title class="justify-center user-basic-info-card-title">
          {{ basicInfo.name }}
        </v-card-title>
      </v-card>
      <v-card
        max-width="400"
        min-width="200"
        color="#2C2C2C"
        elevation="0"
        class="d-flex flex-column ml-2"
      >
        <v-card-subtitle class="pt-2 pb-0 pl-2">
          ЛТВ
        </v-card-subtitle>

        <v-card-title class="justify-center user-basic-info-card-title">
          {{ basicInfo.ltv }}
        </v-card-title>
      </v-card>

      <v-card
        width="270"
        elevation="0"
        class="ml-2 d-flex flex-column"
      >
        <v-card
          color="#2C2C2C"
          elevation="0"
          class="user-basic-info-small-card"
        >
          <v-card-subtitle class="pt-2 pb-0 pl-2">
            Тариф
          </v-card-subtitle>

          <v-card-title class="justify-center py-1 text-uppercase">
            {{ basicInfo.plan }}
          </v-card-title>
        </v-card>
        <v-card
          color="#2C2C2C"
          elevation="0"
          class="mt-2 user-basic-info-small-card"
        >
          <v-card-subtitle class="pt-2 pb-0 pl-2">
            team ID
          </v-card-subtitle>

          <v-card-title class="justify-center py-1">
            {{ basicInfo.id }}
          </v-card-title>
        </v-card>
      </v-card>

      <v-card
        width="270"
        elevation="0"
        class="ml-2 d-flex flex-column"
      >
        <v-card
          color="#2C2C2C"
          elevation="0"
          class="user-basic-info-small-card"
        >
          <v-card-subtitle class="pt-2 pb-0 pl-2">
            Юзеры лимит
          </v-card-subtitle>

          <v-card-title class="justify-center py-1">
            {{ basicInfo.usersLimit }}
          </v-card-title>
        </v-card>
        <v-card
          color="#2C2C2C"
          elevation="0"
          class="mt-2 user-basic-info-small-card"
        >
          <v-card-subtitle class="pt-2 pb-0 pl-2">
            Юзеры факт
          </v-card-subtitle>

          <v-card-title class="justify-center py-1">
            {{ basicInfo.usersCount }}
          </v-card-title>
        </v-card>
      </v-card>

      <v-card
        width="270"
        elevation="0"
        class="ml-2 d-flex flex-column"
      >
        <v-card
          color="#2C2C2C"
          elevation="0"
          class="user-basic-info-small-card"
        >
          <v-card-subtitle class="pt-2 pb-0 pl-2">
            Профиля лимит
          </v-card-subtitle>

          <v-card-title class="justify-center py-1">
            {{ basicInfo.browserProfilesLimit }}
          </v-card-title>
        </v-card>
        <v-card
          color="#2C2C2C"
          elevation="0"
          class="mt-2 user-basic-info-small-card"
        >
          <v-card-subtitle class="pt-2 pb-0 pl-2">
            Профилей факт
          </v-card-subtitle>

          <v-card-title class="justify-center py-1">
            {{ basicInfo.browserProfilesCount }}
          </v-card-title>
        </v-card>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'UserBasicInfo',
  props: {
    basicInfo: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>
.user-basic-info-small-card {
  flex: 1;
}

.user-basic-info-card-title {
  height: 100%;
}
</style>

<template>
  <v-expansion-panel class="mt-4 user-info-details-panel">
    <v-expansion-panel-header>
      {{ title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-list
        v-if="items.length"
        :max-height="maxHeight"
        class="user-details-list-box new-scrollbar"
      >
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          class="user-details-list-box-item"
        >
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ dynamicKey ? item[dynamicKey] : item }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <span v-intersect="handleGet" />
      </v-list>
      <v-list-item-subtitle
        v-else
        class="pa-0 pl-2"
      >
        Нет данных
      </v-list-item-subtitle>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'UserDetailsPanel',
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    maxHeight: {
      type: String,
      default: '250',
    },
    dynamicKey: {
      type: String,
      default: '',
    },
    getNextPage: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    handleGet(_observer, _delay, intersecting) {
      if (intersecting) {
        this.getNextPage();
      }
    },
  },
};
</script>

<style>
.user-info-details-panel {
  border-radius: inherit;
}

.user-details-list-box {
  overflow: auto;
}

.user-details-list-box-item {
  border-bottom: 1px solid #555555;
}
</style>

<template>
  <div class="d-flex align-center justify-space-between mb-2">
    <!-- ЛЕВАЯ СТОРОНА -->
    <div class="d-flex align-center">
<!--      <v-btn-->
<!--        class="px-4 py-3"-->
<!--        color="blue"-->
<!--        :disabled="!filters.segments.length && !filters.grade"-->
<!--        @click="handleFilterUsersList"-->
<!--      >-->
<!--        Фильтровать-->
<!--      </v-btn>-->

<!--      <v-select-->
<!--        v-model="filters.segments"-->
<!--        label="Сегмент"-->
<!--        :items="segments"-->
<!--        multiple-->
<!--        dense-->
<!--        outlined-->
<!--        hide-details-->
<!--        clearable-->
<!--        class="users-top-bar-segment-select mx-3"-->
<!--        :menu-props="{-->
<!--          maxHeight: '300px',-->
<!--          nudgeBottom: 40,-->
<!--          contentClass: 'users-top-bar-segment-select-menu'-->
<!--        }"-->
<!--        @focus="handleGetSegmentsList"-->
<!--      >-->
<!--        <template #selection> {{ '' }} </template>-->

<!--        <template-->
<!--          v-if="filters.segments.length"-->
<!--          #prepend-inner-->
<!--        >-->
<!--          Выбрано: {{ filters.segments.length }}-->
<!--        </template>-->
<!--      </v-select>-->

<!--      <v-select-->
<!--        v-model="filters.grade"-->
<!--        label="Оценка"-->
<!--        :items="userFilterGradesList"-->
<!--        item-text="name"-->
<!--        item-value="value"-->
<!--        dense-->
<!--        outlined-->
<!--        hide-details-->
<!--        clearable-->
<!--        class="users-top-bar-grade-select"-->
<!--        :menu-props="{-->
<!--          maxHeight: '300px',-->
<!--          nudgeBottom: 40,-->
<!--          contentClass: 'users-top-bar-grade-select-menu',-->
<!--        }"-->
<!--      />-->
    </div>

    <!-- ПРАВАЯ СТОРОНА -->
    <div class="d-flex align-center">
      <!-- ДОБАВИТЬ ПОЛЬЗОВАТЕЛЯ -->
      <v-tooltip
        v-if="profile.manageUsers"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            color="primary"
            icon
            class="mr-2"
            v-on="on"
            @click="$store.dispatch('users/openDialog', 'add')"
          >
            <v-icon>
              mdi-account-plus
            </v-icon>
          </v-btn>
        </template>
        <span>Добавить пользователя</span>
      </v-tooltip>

      <!-- ОБНОВИТЬ СПИСОК -->
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            class="ml-2 mr-4"
            color="primary"
            icon
            :disabled="loading.mainTable"
            v-on="on"
            @click="$store.dispatch('users/loadCurrentPage')"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>

      <!-- ПОИСК -->
      <v-text-field
        :value="topbarSearch.searchInput"
        label="Поиск"
        dense
        solo
        single-line
        hide-details
        clearable
        clear-icon="mdi-close"
        :spellcheck="false"
        @input="search"
      >
        <template #prepend-inner>
          <v-icon size="18">
            mdi-magnify
          </v-icon>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
// import userFilterGradesList from '@/constants/users/userFilterGradesList';

export default {
  name: 'UsersTopbar',
  computed: {
    ...mapGetters({
      loading: 'users/loading',
      profile: 'main/profile',
      pagination: 'users/pagination',
      filters: 'users/filters',
      segments: 'users/segments',
    }),
    ...mapFields('users', ['topbarSearch']),

    // userFilterGradesList() {
    //   return userFilterGradesList;
    // },
  },

  methods: {
    handleGetUsers() {
      this.$store.dispatch('users/loadUsers', {
        itemsPerPage: this.pagination.perPage,
        query: this.topbarSearch.searchInput,
        segments: this.filters.segments,
        grade: this.filters.grade,
        sortBy: this.filters.sortBy,
        order: this.filters.order,
      });
    },

    search(name) {
      this.topbarSearch.searchInput = name;
      setTimeout(async () => {
        if (name === this.topbarSearch.searchInput) {
          this.handleGetUsers();
        }
      }, 500);
    },

    // handleFilterUsersList() {
    //   this.handleGetUsers();
    // },

    // handleGetSegmentsList() {
    //   this.$store.dispatch('users/loadSegmentsList');
    // },
  },
};
</script>

<!--<style>-->
<!--.users-top-bar-segment-select,-->
<!--.users-top-bar-grade-select {-->
<!--  max-width: 239px;-->
<!--}-->

<!--.users-top-bar-segment-select .v-select__slot {-->
<!--  flex: 1;-->
<!--}-->

<!--.users-top-bar-segment-select .v-select__slot .v-label {-->
<!--  position: unset !important;-->
<!--}-->

<!--.users-top-bar-segment-select.v-input .v-input__control .v-input__slot .v-input__prepend-inner {-->
<!--  position: fixed;-->
<!--  margin-top: 14px;-->
<!--  color: white;-->
<!--}-->

<!--.users-top-bar-segment-select-menu.v-menu__content .v-list .v-list-item .v-list-item__action,-->
<!--.users-top-bar-grade-select-menu.v-menu__content .v-list .v-list-item .v-list-item__action {-->
<!--  margin-right: 12px;-->
<!--}-->
<!--</style>-->

<template>
  <div class="d-flex">
    <user-info-dialog-details-left-side />
    <user-info-dialog-details-right-side />
  </div>
</template>

<script>
import UserInfoDialogDetailsLeftSide from '@/components/users/userInfo/UserInfoDialogDetailsLeftSide.vue';
import UserInfoDialogDetailsRightSide from '@/components/users/userInfo/UserInfoDialogDetailsRightSide.vue';

export default {
  name: 'UserInfoDetails',
  components: { UserInfoDialogDetailsRightSide, UserInfoDialogDetailsLeftSide },
};
</script>

<template>
  <v-expansion-panel class="mt-4 user-info-details-panel">
    <v-expansion-panel-header>
      {{ title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card
        v-if="items.length"
        :max-height="maxHeight"
        class="user-details-table-list-box new-scrollbar"
      >
        <v-data-table
          fixed-header
          :items="items"
          :headers="headers"
          disable-sort
          :show-select="false"
          dense
          hide-default-footer
          :items-per-page="-1"
          class="user-info-dialog-details-table"
        />
        <span
          class="user-details-table-list-box-bottom-side"
          v-intersect="handleGet"
        />
      </v-card>
      <v-list-item-subtitle
        v-else
        class="pa-0 pl-2"
      >
        Нет данных
      </v-list-item-subtitle>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'UserInfoDetailsTablePanel',
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    maxHeight: {
      type: String,
      default: '250',
    },
    getNextPage: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    handleGet(_observer, _delay, intersecting) {
      if (intersecting) {
        this.getNextPage();
      }
    },
  },
};
</script>

<style>
.user-info-details-panel {
  border-radius: inherit;
}

.user-details-table-list-box {
  overflow: auto;
}

.user-details-list-box-item {
  border-bottom: 1px solid #555555;
}

.theme--dark.user-info-dialog-details-table.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent;
}

.user-details-table-list-box-bottom-side {
  height: 2px;
}
</style>

<template>
  <v-expansion-panels
    multiple
    class="mr-3 user-details-left-side-panels"
    @change="handlePanelClick"
  >
    <user-info-details-panel
      title="Названия профилей"
      :items="dataState.profiles.list"
      dynamic-key="name"
      :get-next-page="() => getNextPage('profiles')"
    />
    <user-info-details-panel
      title="Теги"
      :items="dataState.tags.list"
      dynamic-key="name"
      :get-next-page="() => getNextPage('tags')"
    />
    <user-info-details-panel
      title="Скрипты"
      :items="dataState.scripts.list"
      dynamic-key="name"
      :get-next-page="() => getNextPage('scripts')"
    />
    <user-info-details-panel
      title="Закладки"
      :items="dataState.bookmarks.list"
      dynamic-key="name"
      :get-next-page="() => getNextPage('bookmarks')"
    />
    <user-info-details-panel
      title="Расширения"
      :items="dataState.extensions.list"
      dynamic-key="name"
      :get-next-page="() => getNextPage('extensions')"
    />
    <user-info-details-panel
      title="Стартовые страницы"
      :items="dataState.homepages.list"
      dynamic-key="name"
      :get-next-page="() => getNextPage('homepages')"
    />
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex';
import UserInfoDialogDetailsPanel from '@/components/users/userInfo/UserInfoDialogDetailsPanel.vue';

export default {
  name: 'UserInfoDetailsLeftSide',
  components: { UserInfoDetailsPanel: UserInfoDialogDetailsPanel },
  data() {
    return {
      panels: ['profiles', 'tags', 'scripts', 'bookmarks', 'extensions', 'homepages'],
      dataState: {
        profiles: { page: 0, list: [], noMoreItems: false },
        tags: { page: 0, list: [], noMoreItems: false },
        scripts: { page: 0, list: [], noMoreItems: false },
        bookmarks: { page: 0, list: [], noMoreItems: false },
        extensions: { page: 0, list: [], noMoreItems: false },
        homepages: { page: 0, list: [], noMoreItems: false },
      },
    };
  },
  computed: {
    ...mapGetters({
      forDialog: 'users/forDialog',
    }),
  },
  methods: {
    async getNextPage(type) {
      const { page, noMoreItems, list } = this.dataState[type];

      if (noMoreItems) return;

      const response = await this.$store.dispatch('users/getUserInfo', { type, page: page + 1, id: this.forDialog.user.teamId });

      if (response?.data?.data) {
        this.dataState[type].list = [...list, ...response.data.data[type]];
        this.dataState[type].page += 1;

        if (!response.data.data[type].length) {
          this.dataState[type].noMoreItems = true;
        }
      }
    },

    handlePanelClick(activePanels) {
      if (!activePanels.length) return;

      const activeKeys = activePanels.map((index) => this.panels[index]);
      this.handleSplitForRequest(activeKeys);
    },

    handleSplitForRequest(panels) {
      panels.forEach((panel) => {
        const { page, noMoreItems } = this.dataState[panel];

        if (page === 0 && !noMoreItems) {
          this.getNextPage(panel);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.user-details-left-side-panels {
  height: fit-content;
  flex: 1;
  overflow: hidden;
}

.user-details-left-side-panels .v-expansion-panel:not(:first-child)::after {
  border: none;
}
</style>

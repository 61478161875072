<template>
  <div class="d-flex align-center">
    <v-col :cols="3" class="pa-0">
      <v-combobox
        :value="selectedSegment"
        label="Сегмент"
        :items="segments"
        dense
        outlined
        hide-details
        :disabled="!profile.managePortraits"
        @focus="handleGetSegmentsList"
        @update:search-input="updateSegment"
      />
    </v-col>
    <v-btn
      class="px-4 py-3 ml-3"
      color="blue"
      :disabled="disableChangeSegmentButton"
      @click="handleChangeSegment"
    >
      Изменить сегмент
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserTopBar',
  data() {
    return {
      selectedSegment: '',
    };
  },
  props: {
    basicInfo: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    'basicInfo.segment': {
      handler(newSegment) {
        this.selectedSegment = newSegment;
      },
    },
  },
  computed: {
    ...mapGetters({
      profile: 'main/profile',
      segments: 'users/segments',
      forDialog: 'users/forDialog',
    }),

    disableChangeSegmentButton() {
      return !this.profile.managePortraits || !this.selectedSegment || this.selectedSegment === this.basicInfo.segment;
    },
  },
  methods: {
    handleGetSegmentsList() {
      this.$store.dispatch('users/loadSegmentsList');
    },
    handleChangeSegment() {
      this.$store.dispatch('users/changeSelectedSegment', { id: this.forDialog.user.teamId, segment: this.selectedSegment });
    },
    updateSegment(value) {
      this.selectedSegment = value;
    },
  },
};
</script>

<style>
.user-top-bar-team-id-title {
  background: #2C2C2C;
}
</style>

<template>
  <v-card>
    <!-- ТАБЛИЦА -->
    <v-data-table
      v-model="users.selected"
      :items="users.all"
      item-key="id"
      :headers="cols"
      fixed-header
      :height="innerHeight - 16 - 46 - 59"
      :items-per-page="footerOptions.itemsPerPage"
      hide-default-footer
      :loading="loading.mainTable"
      show-expand
      :expanded.sync="expanded"
      :server="true"
      :server-items-length="users.all.length"
      class="custom-data-table"
      @update:options="onOptionsUpdate"
    >
      <!-- ДЕЙСТВИЯ -->
      <template #[`item.actions`]="{item: user}">
        <cell-actions
          :user="user"
          :user-id="user.id"
        />
      </template>

      <!--КНОПКА РАСКРЫТИЯ ПОД ТАБЛИЦЫ-->
      <template v-slot:item.data-table-expand="{item, isExpanded}">
        <div class="icon-container">
          <v-icon
            :size="20"
            :disabled="!item.users.length"
            @click="handleExpansion(item, isExpanded)"
          >
            {{ !isExpanded ? 'mdi mdi-plus' : 'mdi mdi-minus' }}
          </v-icon>
        </div>
      </template>

      <!-- ЛИЦЕНЗИЯ -->
      <template #[`item.refStat`]="{item: user}">
        <div>
          Баланс: <br> USD {{ user.ref_stat.balance }}
        </div>
        <div>
          Рефералы: {{ user.ref_stat.users_count }}
        </div>
      </template>

      <!--ДАТА РЕГИСТРАЦИИ-->
      <template #[`item.registrationDate`]="{item:date}">
        <span>{{ moment(date.registrationDate ).format('DD.MM.YYYY') }}</span>
      </template>

      <template #[`item.username`]="{item: user}">
        <p
          style="margin: 0"
          :class="{
            'blue--text': user.username
              .toUpperCase()
              .includes(topbarSearch.searchInput.toUpperCase())
          }"
        >
          {{ user.username }}
          <span
            v-if="user.team_deleted_at"
            class="red--text"
          >(удалена тима)</span>
        </p>

        <v-tooltip
          v-if="user.team_deleted_at"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              inline
              style="float: right"
              v-on="on"
              @click="restoreTeam(user.teamId)"
            >
              <v-icon :size="20">
                mdi-backup-restore
              </v-icon>
            </v-btn>
          </template>
          <span>Восстановить Тиму</span>
        </v-tooltip>

        <v-tooltip
          v-if="user.deleted_at"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              inline
              style="float: right"
              v-on="on"
              @click="restoreTeamUser(user.id)"
            >
              <v-icon :size="20">
                mdi-backup-restore
              </v-icon>
            </v-btn>
          </template>
          <span>Восстановить юзера и его профили</span>
        </v-tooltip>
      </template>

      <!-- ЛИЦЕНЗИЯ -->
      <template #[`item.license`]="{item: user}">
        <v-chip
          :color="getPlanChipColor(user.plan)"
          outlined
          label
          small
        >
          {{ user.plan.toUpperCase() }} {{ user.isCustomTariff === 1 ? ' CUSTOM' : '' }}
        </v-chip>

        <span class="mx-3">
          <!-- КОЛИЧЕСТВО ПОЛЬЗОВАТЕЛЕЙ -->
          <span
            :style="{color: getColor(user.usersLimit, user.usersCount)}"
            class="mr-2"
          >
            <v-icon
              :size="16"
              style="color: inherit; position: relative; top: -2px;"
            >
              mdi-account
            </v-icon>
            {{ usersCount(user) + "/" + user.usersLimit }}
          </span>

          <!-- КОЛИЧЕСТВО ПРОФИЛЕЙ -->
          <span :style="{color: getColor(user.browserProfilesLimit, user.browserProfilesCount)}">
            <v-icon
              :size="16"
              style="color: inherit; position: relative; top: -2px;"
            >
              mdi-google-chrome
            </v-icon>
            {{ browserProfilesCount(user) + "/" + user.browserProfilesLimit }}
          </span>
        </span>
        <span class="mr-2">
          до {{ moment(user.subscriptionExpiration).format('DD.MM.YYYY') }}
        </span>

        <!-- СОЗДАНИЕ ПРОФИЛЕЙ -->
        <span
          v-if="user.plan === 'free' &&
           user.browserProfilesLimit === 10"
          class="reset-limit-span"
        >
          <v-icon
            :size="16"
            class="reset-limit-icon"
          >
            mdi-timelapse
          </v-icon>
            {{ user.countForCurrentMonth + "/" + user.limitForCurrentMonth }}
        </span>
      </template>

      <!-- ФУТЕР -->
      <template #footer>
        <v-data-footer
          :options="footerOptions"
          :pagination="footerPagination"
          :items-per-page-options="[10, 30, 50]"
          @update:options="updateFooterOptions"
        />
      </template>

      <!--ДЛЯ КОРЕКТНОГО ОТОБРАЖЕНИЯ БОРДЕРА У  ВТОРИЧНЫХ СТРОК-->
      <template v-slot:expanded-item="{ item }">
        <td class="rootUnderTable">
          <div
            v-for="user in item.users"
            :key="user.id"
            class="containerUnderTable emptyTd"
          />
        </td>

        <td class="rootUnderTable">
          <p
            v-for="user in item.users"
            :key="user.id"
            class="containerUnderTable"
            :class="{
              'blue--text': user.username
                .toUpperCase()
                .includes(topbarSearch.searchInput.toUpperCase()),
            }"
          >
            {{ user.username }}
          </p>
        </td>

        <td class="rootUnderTable">
          <p
            v-for="user in item.users"
            :key="user.id"
            class="containerUnderTable"
          >
            {{ user.telegram }}
          </p>
        </td>

        <td class="rootUnderTable">
          <div
            v-for="id in item.users"
            :key="id.id"
            class="containerUnderTable"
          />
        </td>
        <td class="rootUnderTable">
          <p
            v-for="user in item.users"
            :key="user.id"
            class="containerUnderTable"
          >
            {{ user.role }}
          </p>
        </td>

        <!--ДЛЯ КОРЕКТНОГО ОТОБРАЖЕНИЯ БОРДЕРА У  ВТОРИЧНЫХ СТРОК-->
        <td class="rootUnderTable">
          <div
            v-for="user in item.users"
            :key="user.id"
            class="containerUnderTable"
          />
        </td>

        <td class="rootUnderTable">
          <div
            v-for="user in item.users"
            :key="user.id"
            class="buttonContainer containerUnderTable"
          >
            <v-btn
              text
              @click="showUserHistory(user)"
            >
              История
            </v-btn>
            <!-- ПОДОЗРИТЕЛЬНЫЙ ВХОД -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openSuspiciousEntranceDialog(user)"
                >
                  <v-icon :size="20">
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Показать подозрительный входы</span>
            </v-tooltip>

            <!-- ВЫКЛЮЧИТЬ ИЛИ ВКЛЮЧИТЬ ГЕО 2FA -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openTwoFactorByEmailEnableOrDisableDialog(user)"
                >
                  <v-icon :size="20">
                    mdi-account-sync
                  </v-icon>
                </v-btn>
              </template>
              <span v-if="user.twoFactorByEmailEnable">
                Включить Гео 2FA
              </span>
              <span v-else>
                Выключить Гео 2FA
              </span>
            </v-tooltip>
            <v-btn
              icon
              :disabled="!user.twoFactorEnableDisable"
              @click="disableTwoFactor(user)"
            >
              <v-icon :size="20">
                mdi mdi-two-factor-authentication
              </v-icon>
            </v-btn>
            <v-tooltip
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  inline
                  :disabled="!user.deleted_at"
                  v-bind="attrs"
                  v-on="on"
                  @click="restoreTeamUser(user.id)"
                >
                  <v-icon :size="15">
                    mdi mdi-account-reactivate-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Восстановить юзера и его профили</span>
            </v-tooltip>
            <!-- ПЕРЕИМЕНОВАТЬ ПОЛЬЗОВАТЕЛЯ -->
            <v-tooltip
              v-if="profile.manageUsers"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openRenameUser(user)"
                >
                  <v-icon> mdi-rename-box </v-icon>
                </v-btn>
              </template>
              <span>Переименовать пользователя</span>
            </v-tooltip>

            <!-- ONE-TIME LOGIN CODE -->
            <v-tooltip
              v-if="profile.manageUsers"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="getOneTimeLoginCode(user.id)"
                >
                  <v-icon :size="20">
                    mdi-map-marker-circle
                  </v-icon>
                </v-btn>
              </template>
              <span>Получить код если "подозрительный вход"</span>
            </v-tooltip>
          </div>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import customSort from '@/mixins/customSort';
import CellActions from './UsersMainTableCellActions.vue';

export default {
  name: 'UsersMainTable',

  components: {
    CellActions,
  },
  mixins: [customSort],
  data() {
    return {
      moment,
      expanded: [],
      usersPage: true,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'users/loading',
      innerHeight: 'main/innerHeight',
      pagination: 'users/pagination',
      profile: 'main/profile',
      users: 'users/users',
      topbarSearch: 'users/topbarSearch',
      filters: 'users/filters',
    }),

    cols() {
      const cols = [];

      cols.push({ text: 'Пользователь', value: 'username' });
      cols.push({ text: 'Telegram', value: 'telegram' });
      cols.push({ text: 'Дата регистрации', value: 'registrationDate' });
      cols.push({
        text: 'Лицензия', value: 'license', sortable: false,
      });
      cols.push({ text: 'LTV', value: 'ltv' });
      cols.push({ text: 'Сегмент', value: 'segment', sortable: false });

      if (this.profile.viewReferalBalance) {
        cols.push({
          value: 'refStat', width: 200, sortable: false,
        });
      }

      cols.push({
        value: 'actions', width: 250, align: 'end', sortable: false,
      });

      return cols;
    },

    footerOptions() {
      const options = {};

      options.page = this.pagination.currentPage;
      options.itemsPerPage = this.pagination.perPage;

      return options;
    },

    footerPagination() {
      const pagination = {};

      pagination.page = this.pagination.currentPage;
      pagination.itemsPerPage = this.pagination.perPage;
      pagination.pageStart = this.pagination.from - 1;
      pagination.pageStop = this.pagination.to;
      pagination.pageCount = this.pagination.lastPage;
      pagination.itemsLength = this.pagination.total;

      return pagination;
    },
  },
  methods: {
    onOptionsUpdate(options) {
      if (!this.users.all.length) {
        return;
      }

      const { sortBy: [sortBy] = [], sortDesc: [sortDesc] = [] } = options;
      const isSortDesc = sortDesc ? 'desc' : 'asc';

      const params = {
        page: this.pagination.currentPage,
        itemsPerPage: this.pagination.perPage,
        query: this.topbarSearch.searchInput,
        segments: this.filters.segments,
        grade: this.filters.grade,
      };

      if (sortBy) {
        params.sortBy = sortBy;
        params.order = isSortDesc;
      }

      this.$store.dispatch('users/setFilters', {
        ...this.filters,
        sortBy,
        order: isSortDesc,
      });
      this.$store.dispatch('users/loadUsers', params);
    },

    async showUserHistory(user) {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(user),
        dialog: 'history',
      });

      this.$store.dispatch('users/openDialog', 'history');
    },

    async openSuspiciousEntranceDialog(user) {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(user),
        dialog: 'suspiciousEntrance',
      });

      this.$store.dispatch('users/openDialog', 'suspiciousEntrance');
    },

    async openTwoFactorByEmailEnableOrDisableDialog(user) {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(user),
        dialog: 'twoFactorByEmailEnableOrDisable',
      });
      this.$store.dispatch('users/openDialog', 'twoFactorByEmailEnableOrDisable');
    },

    async disableTwoFactor(user) {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(user),
        dialog: 'disableTwoFactor',
      });

      this.$store.dispatch('users/openDialog', 'disableTwoFactor');
    },

    async openRenameUser(user) {
      await this.$store.dispatch('users/setForDialog', {
        user: this.$_.cloneDeep(user),
        dialog: 'renameUser',
      });

      await this.$store.dispatch('users/openDialog', 'renameUser');
    },

    handleExpansion(item, isExpanded) {
      const itemIndex = this.expanded.indexOf(item);

      if (isExpanded) {
        this.expanded.splice(itemIndex, 1);
      } else {
        this.expanded.push(item);
      }
    },

    getPlanChipColor(plan) {
      if (['trial', 'free'].includes(plan)) {
        return 'primary';
      }
      if (['base'].includes(plan)) {
        return 'success';
      }
      if (['team'].includes(plan)) {
        return 'orange';
      }
      return 'purple';
    },

    updateFooterOptions(options) {
      this.$store.dispatch('users/loadUsers', {
        page: options.page,
        itemsPerPage: options.itemsPerPage,
        query: this.topbarSearch.searchInput,
        segments: this.filters.segments,
        grade: this.filters.grade,
        sortBy: this.filters.sortBy,
        order: this.filters.order,
      });
    },

    usersCount(user) {
      if (user.usersCount) {
        return user.usersCount;
      }
      return 0;
    },

    browserProfilesCount(user) {
      if (user.browserProfilesCount) {
        return user.browserProfilesCount;
      }
      return 0;
    },

    getColor(limit, count) {
      if (count > limit) {
        return '#F44336';
      }
      return 'inherit';
    },

    async getOneTimeLoginCode(userId) {
      const response = await this.api.get(
        `/admin/users/one-time-login-code/${userId}`,
        { validateStatus: () => true },
      );

      if (response.status === 200) {
        alert(`Код: ${response.data?.code || JSON.stringify(response.data)}`);
      } else {
        await this.$store.dispatch('main/alert', {
          color: 'error',
          message: response.data?.error || 'Не удалось получить код',
        });
      }
    },

    async restoreTeamUser(userId) {
      const response = await this.api.post(
        `/admin/users/restore/${userId}`,
        {},
        { validateStatus: () => true },
      );

      if (response.status === 200) {
        await this.$store.dispatch('main/alert', {
          color: 'success',
          message: 'Пользователь и его профили восстановлены',
        });
        this.$store.dispatch('users/loadUsers', {
          query: this.topbarSearch.searchInput,
          segments: this.filters.segments,
          grade: this.filters.grade,
          sortBy: this.filters.sortBy,
          order: this.filters.order,
        });
      } else {
        await this.$store.dispatch('main/alert', {
          color: 'error',
          message: response.data?.error || 'Не удалось восстановить пользователя и/или его профили',
        });
      }
    },
    async restoreTeam(userId) {
      const response = await this.api.post(
        `/admin/users/restore-team/${userId}`,
        {},
        { validateStatus: () => true },
      );

      if (response.status === 200) {
        await this.$store.dispatch('main/alert', {
          color: 'success',
          message: 'Тима пользователя восстановлена',
        });
        this.$store.dispatch('users/loadUsers', {
          query: this.topbarSearch.searchInput,
          segments: this.filters.segments,
          grade: this.filters.grade,
          sortBy: this.filters.sortBy,
          order: this.filters.order,
        });
      } else {
        await this.$store.dispatch('main/alert', {
          color: 'error',
          message: response.data?.error || 'Не удалось восстановить пользователя и/или его профили',
        });
      }
    },
  },
};
</script>

<style>

.custom-data-table > .v-data-table__wrapper > table > tbody tr:hover {
  background: rgba(107, 107, 107, 1);
  box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.12);
}

.custom-data-table > .v-data-table__wrapper > table > tbody tr {
  background: rgba(54, 54, 54, 1);
  box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.12);
}

.rootUnderTable {
  vertical-align: top;
  font-size: 14px;
  padding: 0 !important;
  background: #1E1E1E;
}

.rootUnderTable > p {
  margin: 0;
}

.emptyTd {
  width: 56px;
}

.containerUnderTable {
  height: 54px;
  padding: 16px 20px 16px 16px !important;
  border-bottom: 1px solid rgba(255, 255, 255, .20);
  box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.12);
}

.twoFactorIconButton {
  width: 24px;
  height: 24px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px
}

.icon-container {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container:hover {
  background: rgba(141, 141, 141, 1);
  cursor: pointer;
}

.reset-limit-span {
  white-space: nowrap;
}

.reset-limit-icon {
  color: inherit;
  position: relative;
  top: -2px;
}
</style>

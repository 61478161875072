<template>
  <v-dialog
    :value="dialogs.userInfo"
    fullscreen
    persistent
    @keydown.esc="closeDialog"
    content-class="user-info-dialog"
  >
    <v-card
      class="d-flex flex-column"
      color="black"
    >
      <v-btn
        icon
        width="fit-content"
        height="fit-content"
        class="user-info-dialog-close-button"
        @click="closeDialog"
      >
        <v-icon :size="24" color="grey darken-1">
          mdi-close
        </v-icon>
      </v-btn>

      <div class="pa-10">
        <top-bar :basic-info="basicInfo" />
        <basic-info :basic-info="basicInfo" />
        <info-details />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import TopBar from '@/components/users/userInfo/UserInfoDialogTopBar.vue';
import InfoDetails from '@/components/users/userInfo/UserInfoDialogDetails.vue';
import BasicInfo from '@/components/users/userInfo/UserInfoDialogBasicInfo.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'UserInfoDialog',
  components: { BasicInfo, TopBar, InfoDetails },
  data() {
    return {
      basicInfo: {},
    };
  },
  computed: {
    ...mapGetters({
      dialogs: 'users/dialogs',
      forDialog: 'users/forDialog',
    }),
  },
  created() {
    this.handleGetBasicInfo();
  },
  beforeDestroy() {
    this.closeDialog();
  },
  methods: {
    closeDialog() {
      this.$store.dispatch('users/closeDialog', 'userInfo');
    },

    async handleGetBasicInfo() {
      const response = await this.$store.dispatch('users/getUserInfo', { type: 'basicInfo', id: this.forDialog.user.teamId });

      if (response?.data?.data) {
        this.basicInfo = response.data.data.basicInfo;
      }
    },
  },
};
</script>

<style>
.user-info-dialog::-webkit-scrollbar {
  display: none;
}

.user-info-dialog-close-button {
  right: 22px;
  padding: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 22px;
}
</style>
